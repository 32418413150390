
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accountn3gTymoXiRMeta } from "/opt/buildhome/repo/pages/app/account.vue?macro=true";
import { default as _91project_id_93oIBrCRrVL5Meta } from "/opt/buildhome/repo/pages/app/blink/[project_id].vue?macro=true";
import { default as calendarb4prjGumroMeta } from "/opt/buildhome/repo/pages/app/calendar.vue?macro=true";
import { default as claim6EGFFj9ZCCMeta } from "/opt/buildhome/repo/pages/app/claim.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as inoH3cH0uYVClMeta } from "/opt/buildhome/repo/pages/app/ino.vue?macro=true";
import { default as kwai_45partial_45migrateMTanRc7XtEMeta } from "/opt/buildhome/repo/pages/app/kwai-partial-migrate.vue?macro=true";
import { default as otc0bBn3qCnTKMeta } from "/opt/buildhome/repo/pages/app/otc.vue?macro=true";
import { default as policy0sI0knZrAkMeta } from "/opt/buildhome/repo/pages/app/policy.vue?macro=true";
import { default as private_45sale_45passXFXFzGrclXMeta } from "/opt/buildhome/repo/pages/app/private-sale-pass.vue?macro=true";
import { default as _91project_id_93FVP4p4WXcJMeta } from "/opt/buildhome/repo/pages/app/private/[project_id].vue?macro=true";
import { default as _91project_id_93Z6FjfIOfkbMeta } from "/opt/buildhome/repo/pages/app/project/[project_id].vue?macro=true";
import { default as stakenCv5hC8GUjMeta } from "/opt/buildhome/repo/pages/app/stake.vue?macro=true";
import { default as tg_45link5ZNy0kZt7qMeta } from "/opt/buildhome/repo/pages/app/tg-link.vue?macro=true";
import { default as giftWO6Vqj3a1mMeta } from "/opt/buildhome/repo/pages/gift.vue?macro=true";
import { default as hall_45of_45fame0QEA535VDBMeta } from "/opt/buildhome/repo/pages/hall-of-fame.vue?macro=true";
import { default as index_copyhvKeYbnBJjMeta } from "/opt/buildhome/repo/pages/index_copy.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
export default [
  {
    name: "account",
    path: "/app/account",
    meta: accountn3gTymoXiRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/account.vue")
  },
  {
    name: "blink",
    path: "/app/blink/:project_id()",
    meta: _91project_id_93oIBrCRrVL5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/blink/[project_id].vue")
  },
  {
    name: "calendar",
    path: "/app/calendar",
    meta: calendarb4prjGumroMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/calendar.vue")
  },
  {
    name: "claim",
    path: "/app/claim",
    meta: claim6EGFFj9ZCCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/claim.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: indexIbNDbq129sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "ino",
    path: "/app/ino",
    meta: inoH3cH0uYVClMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/ino.vue")
  },
  {
    name: "kwai-partial-migrate",
    path: "/app/kwai-partial-migrate",
    meta: kwai_45partial_45migrateMTanRc7XtEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/kwai-partial-migrate.vue")
  },
  {
    name: "otc",
    path: "/app/otc",
    meta: otc0bBn3qCnTKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/otc.vue")
  },
  {
    name: "policy",
    path: "/app/policy",
    meta: policy0sI0knZrAkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/policy.vue")
  },
  {
    name: "privateSalePass",
    path: "/app/private-sale-pass",
    meta: private_45sale_45passXFXFzGrclXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/private-sale-pass.vue")
  },
  {
    name: "private",
    path: "/app/private/:project_id()",
    meta: _91project_id_93FVP4p4WXcJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/private/[project_id].vue")
  },
  {
    name: "project",
    path: "/app/project/:project_id()",
    meta: _91project_id_93Z6FjfIOfkbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/project/[project_id].vue")
  },
  {
    name: "stake",
    path: "/app/stake",
    meta: stakenCv5hC8GUjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/stake.vue")
  },
  {
    name: "app-tg-link",
    path: "/app/tg-link",
    component: () => import("/opt/buildhome/repo/pages/app/tg-link.vue")
  },
  {
    name: "gift",
    path: "/gift",
    component: () => import("/opt/buildhome/repo/pages/gift.vue")
  },
  {
    name: "hall-of-fame",
    path: "/hall-of-fame",
    component: () => import("/opt/buildhome/repo/pages/hall-of-fame.vue")
  },
  {
    name: "home",
    path: "/index_copy",
    meta: index_copyhvKeYbnBJjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index_copy.vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  }
]